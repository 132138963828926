import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_board_component = _resolveComponent("board-component")!
  const _component_version_component = _resolveComponent("version-component")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "data-container"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_board_component),
      _createVNode(_component_version_component)
    ]),
    _: 1
  }))
}