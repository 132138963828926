<template>
  <div class="version-footer">
    UI {{ UiVersion }} | API {{ APIVersion}}
  </div>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import { version as UIVersion } from '@/config/Version';

import {Context} from "@/Context";

@Component({})
export default class VersionComponent extends Vue {

  private readonly service = Context.provideSystemService();
  private UiVersion: string = UIVersion;
  private APIVersion: string = '';

  public async getAPIVersion() {
    try {
      this.APIVersion = await this.service.getApiVersion();
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  async mounted() {
    await this.getAPIVersion();
  }

}
</script>

<style scoped>
.version-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  color: #555;
}
</style>