<template>
  <v-container fluid class="data-container">
    <board-component/>
    <version-component/>
  </v-container>
</template>

<script lang="ts">


import {Component, Vue} from "vue-facing-decorator";
import BoardComponent from '@/components/BoardComponent.vue';
import VersionComponent from "@/components/VersionComponent.vue";

@Component({
  components: {
    BoardComponent,
    VersionComponent,
  }
})

export default class HomeView extends Vue {

    async mounted(): Promise<void> {

    }
}
</script>

<style scoped>
.data-container{
  margin-left: 0;
  margin-right: 0;
}
</style>