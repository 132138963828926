<template>
    <v-data-table :headers = "headers" :items = "boards" class="elevation-1 ">
      <template v-slot:item.lastPingDate="{ item }">
        <v-chip color="primary">
          {{ formatTime(item.lastPingDate) }}
        </v-chip>
      </template>
    </v-data-table>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-facing-decorator';
import {BoardData} from "@/entities/BoardData";
import {formatTime} from "../utils/FormatTime";
import {Context} from "@/Context";


@Component({
  methods: {formatTime}
})
export default class BoardComponent extends Vue {
  private readonly boardService = Context.provideBoardService();

  private headers = [
    { title: 'UUID', value: 'uuid' },
    { title: 'MAC', value: 'mac'},
    { title: 'Public IP', value: 'ipPublic'},
    { title: 'Private IP', value: 'ipLocal'},
    { title: 'OS Version', value: 'version'},
    { title: 'Update', value: 'lastPingDate'}
  ];
  private boards: BoardData[] = [];
  private intervalReload : number | null = null;

  public async getBoardData() {
    try {
      this.boards = await this.boardService.getAll();
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

   async mounted() {
    await this.getBoardData();
     const timeout = 5000;
     this.intervalReload = window.setInterval(this.getBoardData, timeout);
  }

  public beforeDestroy() {
    if(this.intervalReload)
      clearInterval(this.intervalReload)
  }

}
</script>

<style scoped>

</style>