
export function formatTime(milliseconds: bigint): string {
    const ms = Number(milliseconds);
    if (ms < 1000) {
        return `${ms} ms`;
    } else if (ms < 60000) {
        return `${Math.floor(ms / 1000)} s`;
    } else if (ms < 3600000) {
        return `${Math.floor(ms / 60000)} min`;
    } else {
        return `${Math.floor(ms / 3600000)} h`;
    }
}