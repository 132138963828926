import getEnv from "@/utils/Environment";
import {BoardService} from "@/services/BoardService";
import {isUndefined} from "@/utils/Types";
import {BoardServiceImpl} from "@/services/BoardServiceImpl";
import {SystemService} from "@/services/SystemService";
import {SystemServiceImpl} from "@/services/SystemServiceImpl";

const apiUrl = getEnv('VUE_APP_API_URL') as string;
const token = getEnv('VUE_APP_API_TOKEN') as string;
export class Context {

    private static boardService?: BoardService;
    private static systemService?: SystemService;

    public static provideBoardService(): BoardService {
        if (isUndefined(this.boardService))
            this.boardService = new BoardServiceImpl(apiUrl, token);
        return this.boardService!;
    }

    public static provideSystemService(): SystemService {
        if (isUndefined(this.systemService))
            this.systemService = new SystemServiceImpl(apiUrl, token);
        return this.systemService!;
    }
}