import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createBlock(_component_v_data_table, {
    headers: _ctx.headers,
    items: _ctx.boards,
    class: "elevation-1"
  }, {
    "item.lastPingDate": _withCtx(({ item }) => [
      _createVNode(_component_v_chip, { color: "primary" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.formatTime(item.lastPingDate)), 1)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["headers", "items"]))
}