import {BoardService} from "@/services/BoardService";
import {BoardData} from "@/entities/BoardData";
import {checkResponseStatusIs200} from "@/services/utils/ResponseUtils";

export class BoardServiceImpl implements BoardService {
    private readonly apiUrl: string;
    private readonly token: string;

    constructor(apiUrl: string, token: string) {
        this.apiUrl = apiUrl;
        this.token = token;
    }

    public async getAll(): Promise<BoardData[]> {
        const response = await fetch(`${this.apiUrl}/board`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "token": `${this.token}`
            },
        });

        checkResponseStatusIs200(response);

        return response.json();
    }
}