import {SystemService} from "@/services/SystemService";
import {checkResponseStatusIs200} from "@/services/utils/ResponseUtils";


export class SystemServiceImpl implements SystemService {
    private readonly apiUrl: string;
    private readonly token: string;

    constructor(apiUrl: string, token: string) {
        this.apiUrl = apiUrl;
        this.token = token;
    }

   public async getApiVersion(): Promise<string> {
        const response = await fetch(`${this.apiUrl}/system/version`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "token": `${this.token}`
            },
        });

        checkResponseStatusIs200(response);

        return response.text();
    }

}